import React from 'react'

import { useStore } from '../../../hooks'
import {
  Confirmation,
  Section,
  FileUploader,
  Name,
  Collateral,
} from '../../../components'

const Data = () => {
  const { state } = useStore()
  return (
    <>
      {state.borrower.ReferenceID && (
        <Section pt={0}>
          <Name color="hint" />
          <Collateral showFullAddress />
        </Section>
      )}
      {!!state.form?.files?.length && (
        <Section heading="Uploaded Files">
          <FileUploader files={state.form.files}>
            <FileUploader.FilesList mt={9} allowRemove={false} />
          </FileUploader>
        </Section>
      )}
    </>
  )
}

const ConfirmPage = ({ location }) => {
  return <Confirmation location={location} data={<Data />} />
}

export default ConfirmPage
